export const MAX_DIMENSIONS = {
  'Full Front': { width: 12.5, height: 15 },
  'Full Back': { width: 12.5, height: 15 },
  'Left Chest': { width: 5, height: 5 },
  'Sleeve': { width: 3, height: 4 }
} as const;

export const APPAREL_TYPES = {
  standard: 'Standard Cotton',
  premium: 'Premium Cotton',
  blend: 'Polyester/Cotton Blend',
  triblend: 'Triblend'
} as const;

export const GARMENT_STYLES = {
  short: 'Short Sleeve',
  long: 'Long Sleeve',
  hoodie: 'Hoodie',
  crewneck: 'Crewneck',
  zip: 'Zip Hoodie'
} as const;

export const PRINT_LOCATIONS = [
  'Full Front',
  'Left Chest',
  'Full Back',
  'Sleeve'
] as const;

export const DEFAULT_PRICING = {
  appName: 'Print Shop Calculator',
  adminTimeDTF: 2.5,
  adminTimeScreenPrinting: 15,
  designTimeDTF: 2.5,
  designTimeScreenPrinting: 30,
  setupTimeDTF: .4,
  setupTimeScreenPrinting: 5,
  printTimeDTF: .3,
  printTimeScreenPrinting: 3,
  margin: 1.5,
  decorationCostDTF: 0.2,
  decorationCostScreenPrinting: 0.01,
  shopRate: 120,
  minQuantityPerLocation: 12,
  apparelPrices: {
    standard: 2.75,
    premium: 4.75,
    blend: 5.50,
    triblend: 6.75
  },
  styleModifiers: {
    short: 0,
    long: 3,
    hoodie: 12,
    crewneck: 10,
    zip: 11
  },
  handlingFees: {
    short: 0.25,
    long: 0.4,
    hoodie: 0.8,
    crewneck: 0.7,
    zip: 0.75
  }
} as const;