import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Calculator } from './components/Calculator';
import { AdminPanel } from './components/AdminPanel';
import { Login } from './components/Login';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Providers } from './components/Providers';
import { ProtectedRoute } from './components/ProtectedRoute';

export function App() {
  return (
    <BrowserRouter>
      <Providers>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Calculator />} />
              <Route path="/admin" element={<Login />} />
              <Route 
                path="/admin/panel" 
                element={
                  <ProtectedRoute>
                    <AdminPanel />
                  </ProtectedRoute>
                } 
              />
            </Routes>
          </main>
          <Footer />
        </div>
      </Providers>
    </BrowserRouter>
  );
}