import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

interface PricingConfig {
  appName: string;
  adminTimeDTF: number;
  adminTimeScreenPrinting: number;
  designTimeDTF: number;
  designTimeScreenPrinting: number;
  setupTimeDTF: number;
  setupTimeScreenPrinting: number;
  printTimeDTF: number;
  printTimeScreenPrinting: number;
  margin: number;
  decorationCostDTF: number;
  decorationCostScreenPrinting: number;
  shopRate: number;
  minQuantityPerLocation: number;
  apparelPrices: {
    standard: number;
    premium: number;
    blend: number;
    triblend: number;
  };
  styleModifiers: {
    short: number;
    long: number;
    hoodie: number;
    crewneck: number;
    zip: number;
  };
  handlingFees: {
    short: number;
    long: number;
    hoodie: number;
    crewneck: number;
    zip: number;
  };
}

const DEFAULT_CONFIG: PricingConfig = {
  appName: 'Print Shop Calculator',
  adminTimeDTF: 0.3,
  adminTimeScreenPrinting: 0.3,
  designTimeDTF: 0.25,
  designTimeScreenPrinting: 0.5,
  setupTimeDTF: 0.02,
  setupTimeScreenPrinting: 0.17,
  printTimeDTF: 0.03,
  printTimeScreenPrinting: 0.02,
  margin: 2,
  decorationCostDTF: 0.06,
  decorationCostScreenPrinting: 0.03,
  shopRate: 110,
  minQuantityPerLocation: 12,
  apparelPrices: {
    standard: 2.75,
    premium: 4.75,
    blend: 5.50,
    triblend: 6.75
  },
  styleModifiers: {
    short: 0,
    long: 3,
    hoodie: 14,
    crewneck: 14,
    zip: 14
  },
  handlingFees: {
    short: 0.25,
    long: 0.50,
    hoodie: 0.75,
    crewneck: 0.75,
    zip: 0.75
  }
};

const MAX_DIMENSIONS = {
  'Full Front': { width: 12.5, height: 15 },
  'Full Back': { width: 12.5, height: 15 },
  'Left Chest': { width: 5, height: 5 },
  'Sleeve': { width: 3, height: 4 }
};

const PriceContext = createContext<ReturnType<typeof usePriceProvider> | null>(null);

function usePriceProvider() {
  const [config, setConfig] = useState<PricingConfig>(DEFAULT_CONFIG);
  const [decorationType, setDecorationType] = useState('DTF');
  const [apparelType, setApparelType] = useState('standard');
  const [garmentStyle, setGarmentStyle] = useState('short');
  const [printLocations, setPrintLocations] = useState<string[]>(['Full Front']);
  const [dimensions, setDimensions] = useState<{ [key: string]: string }>({
    'Full Front': '10x5',
    'Left Chest': '3x3',
    'Full Back': '12x10',
    'Sleeve': '3x2'
  });
  const [inkColors, setInkColors] = useState<{ [key: string]: number }>({ 'Full Front': 1 });
  const [quantity, setQuantity] = useState(12);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const docRef = doc(db, 'config', 'pricing');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setConfig(docSnap.data() as PricingConfig);
        }
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, []);

  const getMinimumQuantity = () => {
    return decorationType === 'DTF' ? 1 : config.minQuantityPerLocation * printLocations.length;
  };

  useEffect(() => {
    const minQty = getMinimumQuantity();
    if (quantity < minQty) {
      setQuantity(minQty);
    }
  }, [decorationType, printLocations]);

  const togglePrintLocation = (location: string) => {
    setPrintLocations(prev => {
      if (prev.includes(location)) {
        const newLocations = prev.filter(loc => loc !== location);
        const newInkColors = { ...inkColors };
        delete newInkColors[location];
        setInkColors(newInkColors);
        return newLocations.length === 0 ? ['Full Front'] : newLocations;
      }
      setInkColors(prev => ({ ...prev, [location]: 1 }));
      return [...prev, location];
    });
  };

  const parseDimensions = (dimStr: string) => {
    const [width = 0, height = 0] = dimStr.split('x').map(d => {
      const num = parseFloat(d);
      return isNaN(num) ? 0 : num;
    });
    return { width, height };
  };

  const calculatePrice = () => {
    try {
      const basePrice = Number(config.apparelPrices[apparelType as keyof typeof config.apparelPrices]);
      const handlingFee = Number(config.handlingFees[garmentStyle as keyof typeof config.handlingFees]);
      const styleModifier = Number(config.styleModifiers[garmentStyle as keyof typeof config.styleModifiers]);

      let totalPrintCost = 0;
      let totalScreens = 0;

      for (const location of printLocations) {
        const { width, height } = parseDimensions(dimensions[location]);
        const maxDim = MAX_DIMENSIONS[location as keyof typeof MAX_DIMENSIONS];
        
        if (!maxDim || width <= 0 || height <= 0 || width > maxDim.width || height > maxDim.height) {
          continue;
        }

        const area = width * height;
        const locationCost = area * (decorationType === 'DTF' ? config.decorationCostDTF : config.decorationCostScreenPrinting);
        totalPrintCost += locationCost * quantity;

        if (decorationType === 'Screen Printing') {
          totalScreens += (inkColors[location] || 1);
        }
      }

      const adminTime = decorationType === 'DTF' ? config.adminTimeDTF : config.adminTimeScreenPrinting;
      const designTime = decorationType === 'DTF' ? config.designTimeDTF : config.designTimeScreenPrinting;
      const setupTime = (decorationType === 'DTF' ? config.setupTimeDTF : config.setupTimeScreenPrinting) * printLocations.length;
      let printTime = (decorationType === 'DTF' ? config.printTimeDTF : config.printTimeScreenPrinting) * printLocations.length * quantity;

      if (decorationType === 'Screen Printing') {
        printTime += 0.4 * totalScreens * quantity;
      }

      const laborCost = (adminTime + designTime + setupTime + printTime) * config.shopRate;
      const apparelCost = (basePrice + handlingFee + styleModifier) * quantity;
      const totalCost = apparelCost + ((laborCost / quantity) + (totalPrintCost / quantity)) * config.margin;
      const pricePerItem = totalCost / quantity;

      return Math.max(pricePerItem, basePrice * 2);
    } catch (error) {
      console.error('Price calculation error:', error);
      return 0;
    }
  };

  return {
    config,
    decorationType,
    setDecorationType,
    apparelType,
    setApparelType,
    garmentStyle,
    setGarmentStyle,
    printLocations,
    togglePrintLocation,
    dimensions,
    setDimensions,
    inkColors,
    setInkColors,
    quantity,
    setQuantity,
    calculatePrice,
    getMinimumQuantity,
    MAX_DIMENSIONS
  };
}

export function PriceProvider({ children }: { children: React.ReactNode }) {
  const value = usePriceProvider();
  return <PriceContext.Provider value={value}>{children}</PriceContext.Provider>;
}

export function usePrice() {
  const context = useContext(PriceContext);
  if (!context) {
    throw new Error('usePrice must be used within a PriceProvider');
  }
  return context;
}