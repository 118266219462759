import { useState, useCallback } from 'react';
import type { PrintLocation, ApparelType, GarmentStyle, DecorationType } from '../types/pricing';
import { DEFAULT_PRICING } from '../config/constants';
import { calculatePrice } from '../utils/calculations';

export function usePricing() {
  const [decorationType, setDecorationType] = useState<DecorationType>('DTF');
  const [apparelType, setApparelType] = useState<ApparelType>('standard');
  const [garmentStyle, setGarmentStyle] = useState<GarmentStyle>('short');
  const [printLocations, setPrintLocations] = useState<PrintLocation[]>(['Full Front']);
  const [dimensions, setDimensions] = useState<Record<PrintLocation, string>>({
    'Full Front': '10x5',
    'Left Chest': '3x3',
    'Full Back': '12x10',
    'Sleeve': '3x2'
  });
  const [inkColors, setInkColors] = useState<Record<PrintLocation, number>>({
    'Full Front': 1
  });
  const [quantity, setQuantity] = useState(1); // Start with DTF minimum

  const getMinimumQuantity = useCallback(() => {
    return decorationType === 'DTF' ? 
      1 : 
      DEFAULT_PRICING.minQuantityPerLocation * printLocations.length;
  }, [decorationType, printLocations.length]);

  // Update quantity when decoration type changes
  const handleDecorationTypeChange = useCallback((newType: DecorationType) => {
    setDecorationType(newType);
    const newMinQty = newType === 'DTF' ? 1 : DEFAULT_PRICING.minQuantityPerLocation * printLocations.length;
    setQuantity(prev => Math.max(newMinQty, prev));
  }, [printLocations.length]);

  const togglePrintLocation = useCallback((location: PrintLocation) => {
    setPrintLocations(prev => {
      const newLocations = prev.includes(location) 
        ? prev.filter(loc => loc !== location)
        : [...prev, location];
      
      // Ensure at least one location is selected
      const finalLocations = newLocations.length === 0 ? ['Full Front'] : newLocations;
      
      // Update quantity if needed for screen printing
      if (decorationType === 'Screen Printing') {
        const newMinQty = DEFAULT_PRICING.minQuantityPerLocation * finalLocations.length;
        setQuantity(prev => Math.max(newMinQty, prev));
      }
      
      return finalLocations;
    });
  }, [decorationType]);

  const updateDimensions = useCallback((location: PrintLocation, value: string) => {
    if (/^\d*\.?\d*x\d*\.?\d*$/.test(value) || value === '') {
      setDimensions(prev => ({ ...prev, [location]: value }));
    }
  }, []);

  const updateInkColors = useCallback((location: PrintLocation, value: number) => {
    const validValue = Math.max(1, Math.floor(value) || 1);
    setInkColors(prev => ({ ...prev, [location]: validValue }));
  }, []);

  const updateQuantity = useCallback((value: number) => {
    const minQty = getMinimumQuantity();
    const validValue = Math.max(minQty, Math.floor(value) || minQty);
    setQuantity(validValue);
  }, [getMinimumQuantity]);

  const getCurrentPrice = useCallback(() => {
    return calculatePrice({
      config: DEFAULT_PRICING,
      decorationType,
      apparelType,
      garmentStyle,
      printLocations,
      dimensions,
      inkColors,
      quantity
    });
  }, [
    decorationType,
    apparelType,
    garmentStyle,
    printLocations,
    dimensions,
    inkColors,
    quantity
  ]);

  return {
    decorationType,
    setDecorationType: handleDecorationTypeChange,
    apparelType,
    setApparelType,
    garmentStyle,
    setGarmentStyle,
    printLocations,
    togglePrintLocation,
    dimensions,
    updateDimensions,
    inkColors,
    updateInkColors,
    quantity,
    updateQuantity,
    getMinimumQuantity,
    getCurrentPrice
  };
}