// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.PUBLIC_FIREBASE_API_KEY || "AIzaSyCyhNvhVow8Sqw1CMu-7YWMWB2yyVKP9zk",
  authDomain: import.meta.env.PUBLIC_FIREBASE_AUTH_DOMAIN || "print-shop-pricing.firebaseapp.com",
  projectId: import.meta.env.PUBLIC_FIREBASE_PROJECT_ID || "print-shop-pricing",
  storageBucket: import.meta.env.PUBLIC_FIREBASE_STORAGE_BUCKET || "print-shop-pricing.appspot.com",
  messagingSenderId: import.meta.env.PUBLIC_FIREBASE_MESSAGING_SENDER_ID || "710001309992",
  appId: import.meta.env.PUBLIC_FIREBASE_APP_ID || "1:710001309992:web:373dd0c8d90814af72781d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);