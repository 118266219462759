import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Moon, Sun, ArrowLeft } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import { useSettings } from '../context/SettingsContext';
import { useAuth } from '../context/AuthContext';

export function Header() {
  const { isDark, toggleTheme } = useTheme();
  const { settings } = useSettings();
  const { user } = useAuth();
  const location = useLocation();
  const isAdminPage = location.pathname.startsWith('/admin');

  return (
    <header className="bg-[var(--color-bg-page)] border-b border-gray-200 dark:border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center space-x-4">
            <img 
              src={isDark ? settings.logoDarkMode : settings.logoLightMode}
              alt={settings.companyName}
              className="h-12"
            />
          </div>
          <div className="flex items-center space-x-4">
            {user && !isAdminPage && (
              <Link 
                to="/admin/panel"
                className="text-default hover:text-primary font-medium"
              >
                Admin Dashboard
              </Link>
            )}
            {isAdminPage && (
              <Link to="/" className="flex items-center text-default hover:text-primary">
                <ArrowLeft className="w-5 h-5 mr-2" />
                Back to Calculator
              </Link>
            )}
            <button
              onClick={toggleTheme}
              className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
              aria-label="Toggle theme"
            >
              {isDark ? (
                <Sun className="w-5 h-5 text-default" />
              ) : (
                <Moon className="w-5 h-5 text-default" />
              )}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}