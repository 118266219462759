import React from 'react';
import { AuthProvider } from '../context/AuthContext';
import { ThemeProvider } from '../context/ThemeContext';
import { PriceProvider } from '../context/PriceContext';
import { SettingsProvider } from '../context/SettingsContext';

interface ProvidersProps {
  children: React.ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  return (
    <ThemeProvider>
      <AuthProvider>
        <SettingsProvider>
          <PriceProvider>
            {children}
          </PriceProvider>
        </SettingsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}