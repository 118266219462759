import { usePricing } from '../hooks/usePricing';
import { APPAREL_TYPES, GARMENT_STYLES, PRINT_LOCATIONS, MAX_DIMENSIONS } from '../config/constants';
import type { PrintLocation } from '../types/pricing';

export function Calculator() {
  const {
    decorationType,
    setDecorationType,
    apparelType,
    setApparelType,
    garmentStyle,
    setGarmentStyle,
    printLocations,
    togglePrintLocation,
    dimensions,
    updateDimensions,
    inkColors,
    updateInkColors,
    quantity,
    updateQuantity,
    getMinimumQuantity,
    getCurrentPrice
  } = usePricing();

  const minQuantity = getMinimumQuantity();
  const price = getCurrentPrice();
  const displayPrice = Number.isFinite(price) ? price.toFixed(2) : '0.00';

  const handleDimensionChange = (location: PrintLocation, type: 'width' | 'height', value: string) => {
    const numValue = parseFloat(value) || 0;
    const currentDims = dimensions[location]?.split('x').map(d => parseFloat(d) || 0) || [0, 0];
    const [width, height] = currentDims;
    const newDims = type === 'width' ? 
      `${numValue}x${height}` : 
      `${width}x${numValue}`;
    updateDimensions(location, newDims);
  };

  const getDimensionValue = (location: PrintLocation, type: 'width' | 'height'): string => {
    const dims = dimensions[location]?.split('x').map(d => parseFloat(d) || 0) || [0, 0];
    return type === 'width' ? dims[0].toString() : dims[1].toString();
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-default mb-2">Custom Apparel Price Calculator</h1>
        <p className="text-lg text-muted">Get instant pricing for your custom apparel</p>
      </div>

      <div className="card rounded-2xl shadow-xl overflow-hidden">
        <div className="p-8 space-y-8">
          {/* Decoration Method */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-default">Decoration Method</h2>
            <div className="flex gap-4">
              {['DTF', 'Screen Printing'].map((method) => (
                <label key={method} className="flex-1">
                  <input
                    type="radio"
                    name="decorationType"
                    value={method}
                    checked={decorationType === method}
                    onChange={(e) => setDecorationType(e.target.value as 'DTF' | 'Screen Printing')}
                    className="sr-only peer"
                  />
                  <div className="p-4 text-center rounded-lg border-2 cursor-pointer transition-all peer-checked:border-primary peer-checked:bg-primary/10 hover:bg-gray-50 dark:hover:bg-gray-800">
                    {method}
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* Apparel Type */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-default">Apparel Type</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {Object.entries(APPAREL_TYPES).map(([value, label]) => (
                <label key={value} className="flex-1">
                  <input
                    type="radio"
                    name="apparelType"
                    value={value}
                    checked={apparelType === value}
                    onChange={(e) => setApparelType(e.target.value as keyof typeof APPAREL_TYPES)}
                    className="sr-only peer"
                  />
                  <div className="p-4 text-center rounded-lg border-2 cursor-pointer transition-all peer-checked:border-primary peer-checked:bg-primary/10 hover:bg-gray-50 dark:hover:bg-gray-800">
                    {label}
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* Garment Style */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-default">Garment Style</h2>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
              {Object.entries(GARMENT_STYLES).map(([value, label]) => (
                <label key={value} className="flex-1">
                  <input
                    type="radio"
                    name="garmentStyle"
                    value={value}
                    checked={garmentStyle === value}
                    onChange={(e) => setGarmentStyle(e.target.value as keyof typeof GARMENT_STYLES)}
                    className="sr-only peer"
                  />
                  <div className="p-4 text-center rounded-lg border-2 cursor-pointer transition-all peer-checked:border-primary peer-checked:bg-primary/10 hover:bg-gray-50 dark:hover:bg-gray-800">
                    {label}
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* Quantity */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-default">Quantity</h2>
            <div className="space-y-2">
              <input
                type="range"
                min={minQuantity}
                max="300"
                value={quantity}
                onChange={(e) => updateQuantity(parseInt(e.target.value, 10))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              />
              <div className="flex items-center justify-center space-x-2">
                <input
                  type="number"
                  min={minQuantity}
                  value={quantity}
                  onChange={(e) => updateQuantity(parseInt(e.target.value, 10))}
                  className="w-24 text-center p-2 rounded border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-default appearance-none"
                />
                <span className="text-lg font-medium text-default">pieces</span>
              </div>
              {decorationType === 'Screen Printing' && minQuantity > 1 && (
                <div className="flex items-center justify-center gap-2 text-amber-600 dark:text-amber-500 mt-2">
                  <span>
                    Minimum {minQuantity} pieces required for {printLocations.length} location{printLocations.length > 1 ? 's' : ''}
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Print Locations */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-default">Print Locations</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {PRINT_LOCATIONS.map((location) => (
                <label key={location} className="relative">
                  <input
                    type="checkbox"
                    checked={printLocations.includes(location)}
                    onChange={() => togglePrintLocation(location as PrintLocation)}
                    className="sr-only peer"
                  />
                  <div className="p-4 text-center rounded-lg border-2 cursor-pointer transition-all peer-checked:border-primary peer-checked:bg-primary/10 hover:bg-gray-50 dark:hover:bg-gray-800">
                    {location}
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* Print Details */}
          {printLocations.length > 0 && (
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-default">Print Details</h2>
              <div className={`grid gap-6 ${decorationType === 'DTF' && printLocations.length > 1 ? 'md:grid-cols-2' : 'grid-cols-1'}`}>
                {printLocations.map((location) => (
                  <div key={location} className="p-6 rounded-lg bg-gray-50 dark:bg-gray-800/50">
                    <h3 className="text-lg font-medium text-default mb-4">{location}</h3>
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-muted mb-2">
                          Dimensions (inches)
                        </label>
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm text-muted mb-1">Width</label>
                            <div className="relative">
                              <input
                                type="number"
                                step="0.1"
                                min="0"
                                max={MAX_DIMENSIONS[location].width}
                                value={getDimensionValue(location, 'width')}
                                onChange={(e) => handleDimensionChange(location, 'width', e.target.value)}
                                className="w-full p-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-default appearance-none"
                              />
                              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-muted">in</span>
                            </div>
                            <p className="text-xs text-muted mt-1">Max: {MAX_DIMENSIONS[location].width}"</p>
                          </div>
                          <div>
                            <label className="block text-sm text-muted mb-1">Height</label>
                            <div className="relative">
                              <input
                                type="number"
                                step="0.1"
                                min="0"
                                max={MAX_DIMENSIONS[location].height}
                                value={getDimensionValue(location, 'height')}
                                onChange={(e) => handleDimensionChange(location, 'height', e.target.value)}
                                className="w-full p-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-default appearance-none"
                              />
                              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-muted">in</span>
                            </div>
                            <p className="text-xs text-muted mt-1">Max: {MAX_DIMENSIONS[location].height}"</p>
                          </div>
                        </div>
                      </div>
                      {decorationType === 'Screen Printing' && (
                        <div>
                          <label className="block text-sm font-medium text-muted mb-2">
                            Number of Colors
                          </label>
                          <input
                            type="number"
                            min="1"
                            value={inkColors[location] || 1}
                            onChange={(e) => updateInkColors(location, parseInt(e.target.value, 10))}
                            className="w-full p-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-default appearance-none"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="bg-gradient-to-r from-sky-500 to-blue-900 p-8">
          <div className="flex items-center justify-between">
            <span className="text-xl font-medium text-white">Price Per Item:</span>
            <span className="text-4xl font-bold text-white">
              ${displayPrice}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}