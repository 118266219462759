import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { CompanySettings, AdminSettings } from '../types/settings';
import { DEFAULT_PRICING } from '../config/constants';

const defaultSettings: CompanySettings = {
  companyName: 'Alpine Apparel Co',
  applicationName: 'Print Shop Calculator',
  applicationTagline: 'Get instant pricing for your custom apparel',
  logoLightMode: 'https://alpineapparelco.com/_astro/Alpine-Apparel-logo.CxU9ll3h_Z1K6B5g.webp',
  logoDarkMode: 'https://alpineapparelco.com/_astro/Alpine-Apparel-logo-white.D7EfnFVI_Zgfjdt.webp',
  copyrightText: '© 2024 Alpine Apparel Co · All rights reserved',
  adminName: '',
  adminEmail: ''
};

interface SettingsContextType {
  settings: CompanySettings;
  updateSettings: (newSettings: Partial<CompanySettings>) => Promise<void>;
  loading: boolean;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export function SettingsProvider({ children }: { children: React.ReactNode }) {
  const [settings, setSettings] = useState<CompanySettings>(defaultSettings);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const docRef = doc(db, 'settings', 'company');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSettings(docSnap.data() as CompanySettings);
        } else {
          await setDoc(docRef, defaultSettings);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const updateSettings = async (newSettings: Partial<CompanySettings>) => {
    try {
      const updatedSettings = { ...settings, ...newSettings };
      await setDoc(doc(db, 'settings', 'company'), updatedSettings);
      setSettings(updatedSettings);
    } catch (error) {
      console.error('Error updating settings:', error);
      throw error;
    }
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings, loading }}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
}