import { MAX_DIMENSIONS } from '../config/constants';
import type { PricingConfig, PrintLocation } from '../types/pricing';

export function parseDimensions(dimStr: string) {
  const [width = 0, height = 0] = dimStr.split('x').map(d => {
    const num = parseFloat(d);
    return isNaN(num) ? 0 : num;
  });
  return { width, height };
}

export function validateDimensions(location: PrintLocation, dimensions: string) {
  const { width, height } = parseDimensions(dimensions);
  const maxDim = MAX_DIMENSIONS[location];
  
  return width > 0 && 
         height > 0 && 
         width <= maxDim.width && 
         height <= maxDim.height;
}

export function calculatePrice({
  config,
  decorationType,
  apparelType,
  garmentStyle,
  printLocations,
  dimensions,
  inkColors,
  quantity
}: {
  config: PricingConfig;
  decorationType: 'DTF' | 'Screen Printing';
  apparelType: keyof PricingConfig['apparelPrices'];
  garmentStyle: keyof PricingConfig['styleModifiers'];
  printLocations: PrintLocation[];
  dimensions: Record<PrintLocation, string>;
  inkColors: Record<PrintLocation, number>;
  quantity: number;
}): number {
  try {
    const basePrice = Number(config.apparelPrices[apparelType]);
    const handlingFee = Number(config.handlingFees[garmentStyle]);
    const styleModifier = Number(config.styleModifiers[garmentStyle]);

    let totalPrintCost = 0;
    let totalScreens = 0;

    for (const location of printLocations) {
      if (!validateDimensions(location, dimensions[location])) continue;

      const { width, height } = parseDimensions(dimensions[location]);
      const area = width * height;
      const locationCost = area * (decorationType === 'DTF' ? 
        config.decorationCostDTF : 
        config.decorationCostScreenPrinting
      );
      
      totalPrintCost += locationCost * quantity;

      if (decorationType === 'Screen Printing') {
        totalScreens += (inkColors[location] || 1);
      }
    }

    const adminTime = decorationType === 'DTF' ? 
      config.adminTimeDTF : 
      config.adminTimeScreenPrinting;
    
    const designTime = decorationType === 'DTF' ? 
      config.designTimeDTF : 
      config.designTimeScreenPrinting;
    
    const setupTime = (decorationType === 'DTF' ? 
      config.setupTimeDTF : 
      config.setupTimeScreenPrinting
    ) * printLocations.length;
    
    let printTime = (decorationType === 'DTF' ? 
      config.printTimeDTF : 
      config.printTimeScreenPrinting
    ) * printLocations.length * quantity;

    if (decorationType === 'Screen Printing') {
      printTime += 0.3 * totalScreens * quantity;
    }

    const laborCost = ((adminTime + designTime + setupTime + printTime)/60) * config.shopRate;
    const apparelCost = basePrice + handlingFee + styleModifier;
    const totalCost = apparelCost + ((laborCost + (totalPrintCost * config.margin)) / quantity);
    
    // Different minimum price checks based on decoration type
    const minPrice = decorationType === 'DTF' ? basePrice * 3 : basePrice * 1.5;
    
    return Math.max(totalCost, minPrice);
  } catch (error) {
    console.error('Price calculation error:', error);
    return 0;
  }
}