import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Settings, Save, User, Building2, Image, Mail, Lock } from 'lucide-react';
import { useSettings } from '../context/SettingsContext';
import { DEFAULT_PRICING } from '../config/constants';
import type { AdminSettings } from '../types/settings';

export function AdminPanel() {
  const { logout, user, updateEmail, updatePassword } = useAuth();
  const { settings, updateSettings } = useSettings();
  const [config, setConfig] = useState(DEFAULT_PRICING);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState('pricing');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchConfig = async () => {
      const docRef = doc(db, 'config', 'pricing');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setConfig(docSnap.data());
      }
    };
    fetchConfig();
  }, []);

  const handleSave = async () => {
    setSaving(true);
    setError('');
    setSuccess('');
    try {
      await setDoc(doc(db, 'config', 'pricing'), config);
      setSuccess('Settings saved successfully!');
    } catch (error) {
      setError('Error saving settings. Please try again.');
    }
    setSaving(false);
  };

  const handleUpdateProfile = async () => {
    setSaving(true);
    setError('');
    setSuccess('');
    try {
      await updateSettings({
        companyName: settings.companyName,
        applicationName: settings.applicationName,
        applicationTagline: settings.applicationTagline,
        logoLightMode: settings.logoLightMode,
        logoDarkMode: settings.logoDarkMode,
        copyrightText: settings.copyrightText,
        adminName: settings.adminName,
        adminEmail: user?.email || ''
      });
      setSuccess('Profile updated successfully!');
    } catch (error) {
      setError('Error updating profile. Please try again.');
    }
    setSaving(false);
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setSaving(true);
    setError('');
    setSuccess('');
    try {
      await updatePassword(newPassword);
      setNewPassword('');
      setConfirmPassword('');
      setSuccess('Password updated successfully!');
    } catch (error) {
      setError('Error updating password. Please try again.');
    }
    setSaving(false);
  };

  const updateConfig = (key: string, value: string) => {
    const numValue = parseFloat(value) || 0;
    setConfig(prev => ({ ...prev, [key]: numValue }));
  };

  const updateNestedConfig = (category: string, key: string, value: string) => {
    const numValue = parseFloat(value) || 0;
    setConfig(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [key]: numValue
      }
    }));
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="card rounded-lg shadow-lg">
        <div className="px-4 py-5 border-b border-[var(--color-border)] sm:px-6">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-default">Admin Dashboard</h1>
            <button onClick={logout} className="btn-secondary">Logout</button>
          </div>
        </div>

        <div className="border-b border-[var(--color-border)]">
          <nav className="flex -mb-px">
            <button
              onClick={() => setActiveTab('pricing')}
              className={`px-6 py-3 font-medium text-sm border-b-2 ${
                activeTab === 'pricing'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-muted hover:text-default hover:border-gray-300'
              }`}
            >
              <Settings className="w-4 h-4 inline-block mr-2" />
              Pricing Configuration
            </button>
            <button
              onClick={() => setActiveTab('company')}
              className={`px-6 py-3 font-medium text-sm border-b-2 ${
                activeTab === 'company'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-muted hover:text-default hover:border-gray-300'
              }`}
            >
              <Building2 className="w-4 h-4 inline-block mr-2" />
              Company Settings
            </button>
            <button
              onClick={() => setActiveTab('account')}
              className={`px-6 py-3 font-medium text-sm border-b-2 ${
                activeTab === 'account'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-muted hover:text-default hover:border-gray-300'
              }`}
            >
              <User className="w-4 h-4 inline-block mr-2" />
              Account Settings
            </button>
          </nav>
        </div>

        <div className="p-6">
          {error && (
            <div className="mb-4 p-4 rounded-md bg-red-50 text-red-700">
              {error}
            </div>
          )}
          {success && (
            <div className="mb-4 p-4 rounded-md bg-green-50 text-green-700">
              {success}
            </div>
          )}

          {activeTab === 'pricing' && (
            <div className="space-y-8">
              {/* DTF Settings */}
              <div className="settings-section">
                <h4 className="text-lg font-medium text-default mb-6">DTF Settings</h4>
                <div className="settings-grid">
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Cost per Square Inch</label>
                    <input
                      type="text"
                      value={config.decorationCostDTF}
                      onChange={(e) => updateConfig('decorationCostDTF', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Admin Time<br /><span className="text-sm text-muted">(in minutes)</span></label>
                    <input
                      type="text"
                      value={config.adminTimeDTF}
                      onChange={(e) => updateConfig('adminTimeDTF', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Design Time<br /><span className="text-sm text-muted">(in minutes)</span></label>
                    <input
                      type="text"
                      value={config.designTimeDTF}
                      onChange={(e) => updateConfig('designTimeDTF', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Setup Time<br /><span className="text-sm text-muted">(in minutes)</span></label>
                    <input
                      type="text"
                      value={config.setupTimeDTF}
                      onChange={(e) => updateConfig('setupTimeDTF', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Print Time<br /><span className="text-sm text-muted">(in minutes)</span></label>
                    <input
                      type="text"
                      value={config.printTimeDTF}
                      onChange={(e) => updateConfig('printTimeDTF', e.target.value)}
                      className="value-input"
                    />
                  </div>
                </div>
              </div>

              {/* Screen Printing Settings */}
              <div className="settings-section">
                <h4 className="text-lg font-medium text-default mb-6">Screen Printing Settings</h4>
                <div className="settings-grid">
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Cost per Square Inch</label>
                    <input
                      type="text"
                      value={config.decorationCostScreenPrinting}
                      onChange={(e) => updateConfig('decorationCostScreenPrinting', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Admin Time<br /><span className="text-sm text-muted">(in minutes)</span></label>
                    <input
                      type="text"
                      value={config.adminTimeScreenPrinting}
                      onChange={(e) => updateConfig('adminTimeScreenPrinting', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Design Time<br /><span className="text-sm text-muted">(in minutes)</span></label>
                    <input
                      type="text"
                      value={config.designTimeScreenPrinting}
                      onChange={(e) => updateConfig('designTimeScreenPrinting', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Setup Time<br /><span className="text-sm text-muted">(in minutes)</span></label>
                    <input
                      type="text"
                      value={config.setupTimeScreenPrinting}
                      onChange={(e) => updateConfig('setupTimeScreenPrinting', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Print Time<br /><span className="text-sm text-muted">(in minutes)</span></label>
                    <input
                      type="text"
                      value={config.printTimeScreenPrinting}
                      onChange={(e) => updateConfig('printTimeScreenPrinting', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Min Quantity per Location</label>
                    <input
                      type="text"
                      value={config.minQuantityPerLocation}
                      onChange={(e) => updateConfig('minQuantityPerLocation', e.target.value)}
                      className="value-input"
                    />
                  </div>
                </div>
              </div>

              {/* General Settings */}
              <div className="settings-section">
                <h4 className="text-lg font-medium text-default mb-6">General Settings</h4>
                <div className="settings-grid">
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Shop Rate ($/hour)</label>
                    <input
                      type="text"
                      value={config.shopRate}
                      onChange={(e) => updateConfig('shopRate', e.target.value)}
                      className="value-input"
                    />
                  </div>
                  <div className="settings-item">
                    <label className="text-sm font-medium text-muted">Margin Multiplier</label>
                    <input
                      type="text"
                      value={config.margin}
                      onChange={(e) => updateConfig('margin', e.target.value)}
                      className="value-input"
                    />
                  </div>
                </div>
              </div>

              {/* Apparel Base Prices */}
              <div className="settings-section">
                <h4 className="text-lg font-medium text-default mb-6">Apparel Base Prices</h4>
                <div className="settings-grid">
                  {Object.entries(config.apparelPrices).map(([key, value]) => (
                    <div key={key} className="settings-item">
                      <label className="text-sm font-medium text-muted">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </label>
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => updateNestedConfig('apparelPrices', key, e.target.value)}
                        className="value-input"
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Style Modifiers */}
              <div className="settings-section">
                <h4 className="text-lg font-medium text-default mb-6">Style Modifiers</h4>
                <div className="settings-grid">
                  {Object.entries(config.styleModifiers).map(([key, value]) => (
                    <div key={key} className="settings-item">
                      <label className="text-sm font-medium text-muted">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </label>
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => updateNestedConfig('styleModifiers', key, e.target.value)}
                        className="value-input"
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Handling Fees */}
              <div className="settings-section">
                <h4 className="text-lg font-medium text-default mb-6">Handling Fees</h4>
                <div className="settings-grid">
                  {Object.entries(config.handlingFees).map(([key, value]) => (
                    <div key={key} className="settings-item">
                      <label className="text-sm font-medium text-muted">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </label>
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => updateNestedConfig('handlingFees', key, e.target.value)}
                        className="value-input"
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-end pt-6">
                <button
                  onClick={handleSave}
                  disabled={saving}
                  className="btn-primary"
                >
                  <Save className="h-4 w-4 mr-2" />
                  {saving ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </div>
          )}

          {activeTab === 'company' && (
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-muted mb-2">Company Name</label>
                <input
                  type="text"
                  value={settings.companyName}
                  onChange={(e) => updateSettings({ companyName: e.target.value })}
                  className="w-full rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-muted mb-2">Application Name</label>
                <input
                  type="text"
                  value={settings.applicationName}
                  onChange={(e) => updateSettings({ applicationName: e.target.value })}
                  className="w-full rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-muted mb-2">Application Tagline</label>
                <input
                  type="text"
                  value={settings.applicationTagline}
                  onChange={(e) => updateSettings({ applicationTagline: e.target.value })}
                  className="w-full rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-muted mb-2">Light Mode Logo URL</label>
                <input
                  type="text"
                  value={settings.logoLightMode}
                  onChange={(e) => updateSettings({ logoLightMode: e.target.value })}
                  className="w-full rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-muted mb-2">Dark Mode Logo URL</label>
                <input
                  type="text"
                  value={settings.logoDarkMode}
                  onChange={(e) => updateSettings({ logoDarkMode: e.target.value })}
                  className="w-full rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-muted mb-2">Copyright Text</label>
                <input
                  type="text"
                  value={settings.copyrightText}
                  onChange={(e) => updateSettings({ copyrightText: e.target.value })}
                  className="w-full rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus"
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleUpdateProfile}
                  disabled={saving}
                  className="btn-primary"
                >
                  <Save className="h-4 w-4 mr-2" />
                  {saving ? 'Saving...' : 'Save Company Settings'}
                </button>
              </div>
            </div>
          )}

          {activeTab === 'account' && (
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-muted mb-2">Email Address</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="email"
                    value={user?.email || ''}
                    disabled
                    className="flex-1 rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus opacity-50"
                  />
                  <Mail className="w-5 h-5 text-muted" />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-muted mb-2">New Password</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password"
                    className="flex-1 rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus"
                  />
                  <Lock className="w-5 h-5 text-muted" />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-muted mb-2">Confirm Password</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password"
                    className="flex-1 rounded-md border-[var(--color-border)] bg-[var(--color-bg-card)] text-default input-focus"
                  />
                  <Lock className="w-5 h-5 text-muted" />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleUpdatePassword}
                  disabled={saving || !newPassword || !confirmPassword}
                  className="btn-primary"
                >
                  <Save className="h-4 w-4 mr-2" />
                  {saving ? 'Updating...' : 'Update Password'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}