import React from 'react';
import { Link } from 'react-router-dom';
import { useSettings } from '../context/SettingsContext';
import { useAuth } from '../context/AuthContext';

export function Footer() {
  const { settings } = useSettings();
  const { user } = useAuth();

  return (
    <footer className="bg-[var(--color-bg-page)] border-t border-gray-200 dark:border-gray-800 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="mb-4 md:mb-0">
            <span className="text-muted">{settings.copyrightText}</span>
          </div>
          <div className="flex items-center space-x-6">
            {user ? (
              <Link to="/admin/panel" className="text-muted hover:text-default transition-colors">
                Admin Dashboard
              </Link>
            ) : (
              <Link to="/admin" className="text-muted hover:text-default transition-colors">
                Admin Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}